<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="coinName" class="form-label">Coin Name</label>
                <InputWrapper :errors="formError.name">
                  <input
                    v-model.number="formData.name"
                    type="text"
                    placeholder="Bitcoin"
                    class="form-control"
                    id="coinName"
                    required
                  />
                </InputWrapper>
                <!-- <Suspense>
                  <template #default>
                    <InputWrapper :errors="formError.coin">
                      <ValidCoinTypeSelector id="selector" v-model="formData.coin" required />
                    </InputWrapper>
                  </template>
                  <template #fallback>Loading coins...</template>
                </Suspense>-->
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="coin_code" class="form-label">Coin code</label>
                <InputWrapper :errors="formError.code">
                  <input
                    v-model="formData.code"
                    type="text"
                    placeholder="BTC"
                    class="form-control"
                    id="coin_code"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="usdRate" class="form-label">USD Rate</label>
                <InputWrapper :errors="formError.usdRate">
                  <input
                    v-model.number="formData.usdRate"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="usdRate"
                    step="any"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Coin Type</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import ValidCoinTypeSelector from "@/components/selector/ValidCoinTypeSelector.vue";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateFiatCurrencyForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      code: null,
      usdRate: null,
      name: null,
    });
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/coin/type`, {
          code: formData.value.code,
          usdRate: formData.value.usdRate,
          name: formData.value.name,
        });
        ShowSuccess("Coin Created Successfully", () => {
          formData.value = {
            code: null,
            usdRate: null,
            name: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create coin: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
