<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create Coin Type</h1>
      <div>
        <small>Coins added here are available for users.</small>
      </div>
    </div>
    <CreateCoinTypeForm />
  </section>
</template>
  
  <script lang="ts">
import { defineComponent } from "vue";
import CreateCoinTypeForm from "@/views/coins/constituent/CreateCoinTypeForm.vue";

export default defineComponent({
  name: "CreateCoinType",
  components: {
    CreateCoinTypeForm,
  },
  setup() {
    return {};
  },
});
</script>
  
  <style scoped></style>
  