
import { defineComponent } from "vue";
import CreateCoinTypeForm from "@/views/coins/constituent/CreateCoinTypeForm.vue";

export default defineComponent({
  name: "CreateCoinType",
  components: {
    CreateCoinTypeForm,
  },
  setup() {
    return {};
  },
});
